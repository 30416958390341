<template>
<header>
       <nav>
  

  
  <a href="/blog" class="current">Blog</a>
  
              <a href="https://www.linkedin.com/in/jerschwartz/" class="text-blue-500 hover:text-blue-700" target="_blank">
              <font-awesome-icon :icon="['fab', 'linkedin']" />
            </a>
            <a href="https://www.instagram.com/jbot/" class="text-pink-500 hover:text-pink-700" target="_blank">
    
<font-awesome-icon :icon="['fab', 'instagram']" />
            </a>


  
      </nav>
          
</header>

</template>

<script>
export default {
  name: 'PageHeader'
}
</script>

<style scoped>
</style>
