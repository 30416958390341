<template>
  <PageHeader></PageHeader>
  
  <div class="min-h-screen bg-gray-100 py-6 flex flex-col justify-center sm:py-12">
    <div class="relative py-3 md:max-w-[90vw] md:mx-auto">
      <div class="bg-white shadow rounded-3xl sm:p-10">
        
        <!-- Blog Post Title -->
        <h1 class="text-4xl font-semibold text-blue-700 mb-4">{{ post.title }}</h1>

        <!-- Author and Date Info -->
        <div class="mb-8">
          <p class="text-sm text-gray-600">By {{ post.author }} - {{ post.date }}</p>
        </div>

        <!-- Blog Image -->
        <img :src="post.image" :alt="post.imageDescription" class="w-full object-cover rounded-md mb-8">

        <!-- Blog Content -->
        <div class="prose max-w-full text-gray-700 mb-8" v-html="post.content"></div>
        
        
        

        <!-- Back to Blog Listing Link -->
        <a href="/blog" class="text-blue-500 hover:underline">← Back to Blog Listing</a>

      </div>
    </div>
    
    <div class="text-center p-6">
      <div class="row mt-5">
        <div class="col-12 text-center">
          <p>
            Copyright Jeremy Schwartz&copy; 2024 All rights reserved
          </p>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
import PageHeader from './PageHeader.vue';

export default {
  name: 'BlogPost',
  components: {
    PageHeader
  },
    props: {
	id: {
	type: String, // or Number, depending on your use case
	required: true
	}
    },    
  data() {
    return {
      post: {
        
      }
    }
  },
    mounted() {


	console.log("Mounted:",this.id)



  fetch(`/posts/${this.id}.json`)
	   .then(response => response.json())
	    .then(data => {
		console.log("Data",data)
		this.post = data

		fetch(`/posts/${this.id}.html`)
		      .then(response => response.text())
		    .then(data => {
		
			    //console.log(data)
			    this.post.content = data
			}
			 )
			 
    // Do something with your data
    });

    }
    
}


/*
//add to mounted
  


  */
</script>
