<template>
  <!--
  <PageHeader></PageHeader>
  -->
  
<header>
      <nav>
  

  
  <a href="/blog" class="current">Blog</a>
  
              <a href="https://www.linkedin.com/in/jerschwartz/" class="text-blue-500 hover:text-blue-700" target="_blank">
              <font-awesome-icon :icon="['fab', 'linkedin']" />
            </a>
            <a href="https://www.instagram.com/jbot/" class="text-pink-500 hover:text-pink-700" target="_blank">
    
<font-awesome-icon :icon="['fab', 'instagram']" />
            </a>


  
      </nav>
           
</header>


<main>
  <div>

    <div>
      <div>

        <!-- Blog Posts Loop -->
        <div v-for="post in posts" :key="post.id">

         

          <!-- Content Area -->
          <div>

            <!-- Post Info -->
            <h2>{{ post.title }}</h2>
            <p>By {{ post.author }} - {{ post.date }}</p>

	     <!-- Image -->
          <a :href="'/blog/' + post.id" class="lg:w-2/4">
            <img :src="post.image" :alt="post.imageDescription">
          </a>
            <!-- Excerpt -->
            <p >{{ post.excerpt }}</p>

            <!-- Read More Link -->
            <a :href="'/blog/' + post.id" >Read More</a>
          </div>
        </div>
      </div>
    </div>
  </div>

  </main>
</template>



<script>
import PageHeader from '@/components/PageHeader.vue';

//convert this to fetch as well
//import blogData from '@/assets/data/blog_listing.json';

export default {
name: 'BlogListing',
    components: {
        PageHeader
    },
    data() {
        return {
            posts: []
        }
    },
    mounted() {
	console.log("Mounted")

 fetch(`/posts/blog_listing.json`)
	   .then(response => response.json())
	    .then(data => {
		console.log("Data",data)
		
			 
		this.posts = data
		})
	
	}
}
</script>

<style scoped>
/* Add any additional component-specific styles here */
img {
  width: 50%; /* Makes the image 50% of its original width */
  height: auto; /* Maintains the aspect ratio */
}
</style>
