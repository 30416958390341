import { createApp } from 'vue'

import App from './App.vue'
//import './assets/styles.css'
import router from './router';
import VueGtag from "vue-gtag";
//import 'normalize.css';
//import 'concrete.css'; // Ensure this path matches the actual CSS file you intend to use

import 'simpledotcss/simple.css';


import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
//import { faInstagram } from '@fortawesome/free-solid-svg-icons'
import { faLinkedin, faInstagram } from '@fortawesome/free-brands-svg-icons';

library.add(faInstagram)
library.add(faLinkedin)

createApp(App).use(VueGtag, {
  config: { id: 'G-12K8ZQE563' }
}).use(router)
    .component('font-awesome-icon', FontAwesomeIcon).
    mount("#app");


//createApp(App).use(router).mount('#app');
